import React from 'react'
import Modal from '@material-ui/core/Modal'
import Paper from '@material-ui/core/Paper'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { StyleSheet, css } from 'aphrodite'

import images from '../images'
import Header from './Header'
import EditablePostIt from './EditablePostIt'
import AspectRatioImg from './AspectRatioImg'
import { useAddPostItHandler, useCircularNavigation } from '../hooks'

const ANIMATION_LENGTH = 1000

const modalContent = [
  {
    title: 'Virtual Tea Break',
    content:
      'Send invites out for a virtual tea break (via Google Hangout, Zoom or any other VC platform). We love a mundane chat, quick quiz and personal check-ins.',
    imageName: 'Teabreaks',
  },
  {
    title: 'Show Your Space',
    content:
      'Make a video of your new work space, give a virtual tour or simply take a photo. It’s nice to share and tell, and helps bring others closer to you despite the screen.',
    imageName: 'DeskSpace',
  },
  {
    title: 'Everyone is different',
    content:
      'Everyone works in different ways when it comes to comms styles, and may need varying levels of contact. Have a frank conversation about what works best for you, and respect others’ preferences.',
    imageName: 'Diversity',
  },
  {
    title: 'Shake it up',
    content:
      'Shake up modes of communication; Slack, WhatsApp, VC or just pick up the phone. Variety is key to avoid any communication rutts but do consider what’s appropriate for nature of content.',
    imageName: 'ShakeItUp',
  },
]

const PostItAdder: React.FC = () => {
  const inputRef = React.useRef<HTMLTextAreaElement>(null)
  const { postIt, setPostItMessage, setPostItName, canSubmitPostIt, submitPostIt, postItCount } = useAddPostItHandler()
  const circularNavigation = useCircularNavigation()
  const [modalOpen, setModalOpen] = React.useState(false)
  const [modalPage, setModalPage] = React.useState(0)

  const focusInput = () => {
    inputRef.current && inputRef.current.focus()
    return undefined
  }

  React.useEffect(
    () => focusInput,
    // TODO: needs fixing as an update to ref may not fire an effect
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [postIt.message, inputRef.current],
  )

  const closeModal = () => {
    setModalOpen(false)
    setModalPage(0)
  }

  return (
    <div className={css(styles.container)}>
      <Header link={circularNavigation ? { to: '/postits', text: 'messages' } : undefined} />
      <div className={css(styles.contentContainer)}>
        <div className={css(styles.topContent)}>
          <div className={css(styles.topContentText)}>
            <p className="large">How might we still feel connected to colleagues and companies?</p>
            <button className={css(styles.button)} type="button" onClick={() => setModalOpen(true)}>
              Read Mettle&apos;s tips
            </button>
          </div>
        </div>
        <form
          className={css(styles.mainContent)}
          onSubmit={event => {
            event.preventDefault()
            event.stopPropagation()
            submitPostIt()
            return false
          }}
        >
          <label className="big">Share your top tips below</label>
          <EditablePostIt
            ref={inputRef}
            key={`current-${postItCount}`}
            className={css(styles.newPostIt, styles.fadeIn)}
            postIt={postIt}
            onNameChange={setPostItName}
            onMessageChange={setPostItMessage}
            onSubmit={submitPostIt}
            readOnly={false}
          />
          <button
            disabled={!canSubmitPostIt}
            className={`alt-font ${css(styles.submit, !canSubmitPostIt && styles.disabled)}`}
            type="submit"
          >
            Submit
          </button>
        </form>
      </div>
      <Modal open={modalOpen} onClose={closeModal}>
        <Paper className={css(styles.modal)}>
          <div className={css(styles.modalTitle)}>
            <p className="large end">Mettle&apos;s top tips</p>
            <IconButton onClick={closeModal}>
              <CloseIcon />
            </IconButton>
          </div>
          <AspectRatioImg
            naturalHeight={601}
            naturalWidth={1000}
            src={images[modalContent[modalPage].imageName as keyof typeof images]}
            alt={modalContent[modalPage].title}
            width="100%"
          />
          <p className={`big ${css(styles.modalSubTitle)}`}>{modalContent[modalPage].title}</p>
          <p className="end">{modalContent[modalPage].content}</p>
          <button
            className={css(styles.button, styles.modalButton)}
            type="button"
            onClick={() => setModalPage(modalPage === modalContent.length - 1 ? 0 : modalPage + 1)}
          >
            Next
          </button>
          <img src={images.DeskSpace} style={{ display: 'none' }} alt={modalContent[modalPage].title} />
          <img src={images.Diversity} style={{ display: 'none' }} alt={modalContent[modalPage].title} />
          <img src={images.ShakeItUp} style={{ display: 'none' }} alt={modalContent[modalPage].title} />
        </Paper>
      </Modal>
    </div>
  )
}

const fadeInKeyframes = {
  '0%': { opacity: 0 },
  '50%': { opacity: 0 },
  '100%': { opacity: 1 },
}

const movementAmount = window.innerHeight - 255

const moveUpKeyframes = {
  '0%': { top: 0, opacity: 1 },
  '80%': { top: -movementAmount, opacity: 1 },
  '100%': { top: -movementAmount, opacity: 0 },
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#fff',
    overflow: 'hidden',
  },

  contentContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    textAlign: 'center',
  },

  topContent: {
    flexShrink: 0,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: '#f2f2f2',
    width: '100%',
  },

  topContentText: {
    marginLeft: 25,
    marginRight: 25,
    marginTop: 50,
    marginBottom: 50,
    maxWidth: 500,
  },

  mainContent: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    minWidth: 300,
    marginLeft: 25,
    marginRight: 25,
    marginTop: 50,
    marginBottom: 50,
    alignItems: 'center',
  },

  newPostIt: {
    margin: 22,
  },

  fadeIn: {
    animationName: fadeInKeyframes,
    animationDuration: `${ANIMATION_LENGTH}ms`,
  },

  moveUp: {
    animationName: moveUpKeyframes,
    animationDuration: `${ANIMATION_LENGTH}ms`,
  },

  submit: {
    backgroundColor: '#0000',
    borderColor: '#000',
    padding: '8px 24px',
  },

  disabled: {
    borderColor: '#999',
    color: '#999',
    padding: '8px 24px',
  },

  footerImage: {
    height: 120,
  },

  button: {
    border: 'none',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    padding: 10,
    ':focus': {
      outline: 0,
    },
    ':hover': {
      backgroundColor: 'rgba(0,0,0,0.05)',
    },
  },

  modal: {
    position: 'absolute',
    backgroundColor: '#fff',
    padding: 25,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxHeight: `calc(100vh - 60px)`,
    maxWidth: `calc(100vw - 60px)`,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    alignItems: 'flex-start',
    width: 450,
    ':focus': {
      outline: 0,
    },
  },

  modalTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    marginBottom: 20,
  },

  modalSubTitle: {
    marginTop: 10,
  },

  modalButton: {
    marginTop: 20,
    alignSelf: 'center',
    border: '1px solid #ccc',
  },
})

export default PostItAdder
