import React, { createContext, useReducer, useEffect, Dispatch } from 'react'
import firestore from '../firestore'

import { IStoredPostIt, IPostItAction } from './types'
import ActionNames from './ActionNames'
import { loadPostIts } from './actions'

const postItCollection = firestore.collection('/hmws/connected/postIts')

const reducer = (state: IStoredPostIt[] | undefined, action: IPostItAction) => {
  switch (action.type) {
    case ActionNames.ADD_POSTIT:
      postItCollection.add(action.postIt)
      return [...(state || []), action.postIt]
    case ActionNames.LOAD_POSTITS:
      return action.postIts
    default:
      return state
  }
}

interface IContext {
  state: IStoredPostIt[] | undefined
  dispatch: Dispatch<IPostItAction>
}
export const StoreContext = createContext<IContext>({ state: undefined, dispatch: () => null })

export const PostItStoreProvider: React.FC = ({ children }: React.PropsWithChildren<{}>) => {
  const [state, dispatch] = useReducer(reducer, undefined)

  useEffect(() => {
    const unsubscribe = postItCollection.orderBy('timestamp').onSnapshot(snapshot => {
      dispatch(loadPostIts(snapshot.docs.map(doc => doc.data() as IStoredPostIt)))
    })

    return () => {
      unsubscribe()
    }
  }, [])

  return <StoreContext.Provider value={{ state, dispatch }}>{children}</StoreContext.Provider>
}
