import DeskSpace from './DeskSpace.png'
import Diversity from './Diversity.png'
import Teabreaks from './Teabreaks.png'
import ShakeItUp from './ShakeItUp.png'

export default {
  DeskSpace,
  Diversity,
  Teabreaks,
  ShakeItUp,
}
