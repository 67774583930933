import React, { useRef, useLayoutEffect, useState } from 'react'
import { StyleSheet, css } from 'aphrodite'

import { IPositionedPostIt } from '../hooks/types'

const ANIMATION_LENGTH = 1000

interface IProps extends IPositionedPostIt {
  fading?: boolean
}

const PostIt: React.FC<IProps> = ({ message, name, position, backgroundColor, fading }) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const [height, setHeight] = useState<number | undefined>(undefined)

  const dynamicStyle = {
    left: `${position.x}%`,
    top: `${position.y}%`,
    opacity: height !== undefined ? 1.0 : 0.0,
    marginTop: height !== undefined ? `-${height / 2}` : 0,
    backgroundColor,
  }

  useLayoutEffect(() => {
    setHeight(containerRef.current!.clientHeight)
  }, [])

  return (
    <div ref={containerRef} className={css(styles.container, fading ? styles.fadeOut : styles.fadeIn)} style={dynamicStyle}>
      {name && <p className={`light small ${css(styles.name)}`}>{name}</p>}
      <p className={`large ${css(styles.message)}`}>{message}</p>
      <p className={`light small end ${css(styles.footer)}`}>Mettle X Navigating the New Normal</p>
    </div>
  )
}

const postItWidth = 255

const fadeInKeyframes = {
  '0%': { opacity: 0 },
  '50%': { opacity: 0 },
  '100%': { opacity: 1 },
}

const fadeOutKeyframes = {
  '0%': { opacity: 1 },
  '50%': { opacity: 0 },
  '100%': { opacity: 0 },
}

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    width: postItWidth,
    marginLeft: -postItWidth / 2,
    padding: 20,
    paddingTop: 30,
    boxShadow: '0px 5px 20px 0px rgba(0,0,0,0.2)',
    textAlign: 'left',
  },
  message: {
    marginTop: -2,
    textAlign: 'center',
    overflow: 'hidden',
  },
  name: {
    marginTop: -10,
    textAlign: 'center',
    overflow: 'hidden',
  },
  footer: {
    textAlign: 'center',
    overflow: 'hidden',
  },
  fadeOut: {
    opacity: 0,
    animationName: fadeOutKeyframes,
    animationDuration: `${ANIMATION_LENGTH}ms`,
  },
  fadeIn: {
    animationName: fadeInKeyframes,
    animationDuration: `${ANIMATION_LENGTH}ms`,
  },
  visible: {
    opacity: 1,
  },
  invisible: {
    opacity: 0,
  },
})

export default PostIt
