import * as firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/analytics'

const config = {
  apiKey: 'AIzaSyAEPpUkwxcWctEfcEUIvdP4ngMx-iXkEe4',
  authDomain: 'mettle-isolation-13388.firebaseapp.com',
  databaseURL: 'https://mettle-isolation-13388.firebaseio.com',
  projectId: 'mettle-isolation-13388',
  storageBucket: 'mettle-isolation-13388.appspot.com',
  messagingSenderId: '354473392894',
  appId: '1:354473392894:web:b902c2f7429c2681f0c3af',
  measurementId: 'G-4M5YE1PKJM',
}
firebase.initializeApp(config)

export default firebase.firestore()
