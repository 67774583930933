import React from 'react'
import { Route } from 'react-router-dom'
import { StyleSheet, css } from 'aphrodite'

import Router from './components/Router'
import { PostItStoreProvider } from './post-its'
import PostItAdder from './components/PostItAdder'
import PostItViewer from './components/PostItViewer'

const App: React.FC = () => {
  return (
    <PostItStoreProvider>
      <div className={css(styles.container)}>
        <Router>
          <Route exact path="/" component={PostItAdder} />
          <Route path="/postits" component={PostItViewer} />
        </Router>
      </div>
    </PostItStoreProvider>
  )
}

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    width: '100vw',
    overflow: 'hidden',
  },
})

export default App
