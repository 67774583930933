import React from 'react'
import { RouteChildrenProps } from 'react-router'
import { BrowserRouter, Route } from 'react-router-dom'

export const RouterContext = React.createContext<RouteChildrenProps<any, any> | null>(null)

const Router = ({ children }: React.PropsWithChildren<{}>) => (
  <BrowserRouter>
    <Route>{routeProps => <RouterContext.Provider value={routeProps}>{children}</RouterContext.Provider>}</Route>
  </BrowserRouter>
)

export default Router
