import { last, sampleSize } from 'lodash'
import { useState, useEffect } from 'react'

import { usePostItStore } from '.'
import { IPositionedPostIt } from './types'

function usePositionedPostItSelection(useLast?: boolean) {
  const [postItsLength, setPostItsLength] = useState<number | undefined>(undefined)
  const [previousPositionedPostIts, setPreviousPositionedPostIts] = useState<IPositionedPostIt[] | undefined>(undefined)
  const [positionedPostIts, setPositionedPostIts] = useState<IPositionedPostIt[] | undefined>(undefined)
  const { state } = usePostItStore()

  useEffect(() => {
    const randomPosition = () => ({
      position: {
        x: Math.random() * 80 + 10,
        y: Math.random() * 80 + 10,
      },
    })

    const resample = () => {
      if (state === undefined) {
        setPositionedPostIts(undefined)
        setPostItsLength(undefined)
      } else {
        const selection = sampleSize(state, 20).map(postit => ({
          ...postit,
          ...randomPosition(),
        }))
        if (useLast && selection.length === 20) {
          selection[19] = {
            ...last(state)!,
            ...randomPosition(),
          }
        }
        setPositionedPostIts(selection)
      }
    }

    if (positionedPostIts === undefined) {
      resample()
    } else if (positionedPostIts && postItsLength && state && postItsLength < state.length) {
      setPositionedPostIts([
        ...positionedPostIts,
        {
          ...last(state)!,
          ...randomPosition(),
        },
      ])
    }
    setPostItsLength(state ? state.length : undefined)

    // refresh every five seconds if nothing changes
    const timeout = setTimeout(() => {
      setPreviousPositionedPostIts(positionedPostIts)
      resample()
    }, 20000)
    return () => clearTimeout(timeout)
  }, [positionedPostIts, postItsLength, state, useLast])

  return { positionedPostIts, previousPositionedPostIts }
}

export default usePositionedPostItSelection
