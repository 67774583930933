import React from 'react'
import { StyleSheet, css } from 'aphrodite'

export interface CardProps {
  src: string
  alt: string
  naturalHeight: number
  naturalWidth: number
  width: string
}

const AspectRatioImg: React.FC<CardProps> = props => {
  const { src, alt, naturalHeight, naturalWidth, width } = props

  const style = {
    paddingTop: `${(naturalHeight / naturalWidth) * 100}%`,
  }
  return (
    <div className={css(styles.box)} style={style}>
      <img className={css(styles.inside)} src={src} alt={alt} width={width} />
    </div>
  )
}

const styles = StyleSheet.create({
  box: {
    width: '100%',
    overflow: 'hidden',
    background: '#fff',
    position: 'relative',
  },
  inside: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
})

export default AspectRatioImg
